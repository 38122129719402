import React from 'react';
import { Alert, VechaiProvider } from '@vechaiui/react';

import styled from 'styled-components';
import Header from './Header';
import { Helmet } from 'react-helmet';

const Layout = ({children}) => {
    return (
        <VechaiProvider>
            <Helmet>
                <title>DApp | CardanoCastle</title>
            </Helmet>
            <div className="min-h-screen h-screen" id="app">
                <LayoutWrap>
                    <Header />
                    <Alert variant='solid'>Please connect your wallet to use our DApp.</Alert>
                    <main className="main-area">
                        {children}
                    </main>
                </LayoutWrap>
            </div>
        </VechaiProvider>
    )
}

export default Layout;

const LayoutWrap = styled.main`
    font-family: 'Rubik';
    max-width: 1400px;
    margin: 0 auto;

    @media (max-width: 800px) {
        padding: 10px;
    }

    main.main-area {
        margin-top: 40px;
        overflow-y: auto;
        height: calc(100vh - 200px);
        padding-bottom: 100px;
    }

    .asset-list {
        overflow-y: scroll;
        max-height: 80vh;
    }
`