import { Button, useNotification } from '@vechaiui/react';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

const Header = () => {
    const [connected, setConnected] = useState(false);
    const [connectionLoading, setConnectionLoading] = useState(false);
	const notification = useNotification();

    const handleConnect = async () => {
        setConnectionLoading(true);
        if (window.cardano) {
            try {
                const res = await window.cardano.enable();
                if (res) {
                    setConnectionLoading(false);
                    setConnected(true);
                    notification({
                        title: 'Success!',
                        description: 'Successfully connected to Nami! ✅',
                        status: 'success',
                        position: 'top',
                    });
                } else {
                    setConnectionLoading(false);
                    setConnected(false);
                    notification({
                        title: 'Error ❌',
                        description: 'An error occured while connecting to Nami.',
                        status: 'error',
                        position: 'top',
                    });
                }
            } catch (error) {
                setConnectionLoading(false);
                setConnected(false);
                notification({
                    title: 'Error ❌',
                    description: 'An error occured while connecting to Nami.',
                    status: 'error',
                    position: 'top',
                });
            }
        } else {
            setConnectionLoading(false);
            setConnected(false);
            notification({
                title: 'Error ❌',
                description: 'Are you sure Nami Wallet is installed?',
                status: 'warning',
                position: 'top',
            });
        }
	};


    const handleDisconnect = async () => {
        notification({
            title: 'Disconnected from Nami! ❌',
            status: 'error',
            position: 'top',
        });
        setConnected(false);
    }

    const handleMint = () => {
        notification({
            title: 'Disabled!',
            description: 'Minting is disabled, please wait for an announcement!',
            status: 'info',
            position: 'top',
        });  
    }

	return (
		<HeaderWrap>
            <div className="logo">
                <Link to="/">
                    <img src="/logo/full_logo_1.png" alt="Logo" />
                </Link>
            </div>
            {!isMobile && (
                <div className="menu-items">
                    <Link className='menu-item' activeClassName='menu-item--active-tab' to="/assets">
                        Assets
                    </Link>
                    <Link className='menu-item' activeClassName='menu-item--active-tab' to="/explore">
                        Explore
                    </Link>
                    <Link className='menu-item' activeClassName='menu-item--active-tab' to="/heroes">
                        Heroes
                    </Link>
                    <div className="menu-item">Rewards</div>
                    <div className="menu-item">Guide</div>
                </div>
            )}
			<div className="header-actions">
                {/* {!isMobile && (
                    !connected ? (
                        <Button loading={connectionLoading} onClick={() => handleConnect('info')} variant="solid" color="primary">
                            Connect Nami
                        </Button>
                    ) : (
                        <Button onClick={() => handleDisconnect()} variant="solid" color="red">
                            Disconnect
                        </Button>
                    )
                )} */}
			</div>
		</HeaderWrap>
	);
};

export default Header;

const HeaderWrap = styled.nav`
	height: 90px;
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: space-between;

    #mint-button {
        margin-right: 10px;
    }

	.logo {
        width: 25%;
		height: 70%;

		img {
			height: 100%;
		}
	}

	.menu-items {
        width: 50%;
		display: flex;
		align-items: center;
        justify-content: center;

		.menu-item {
			display: flex;
			justify-content: center;
			margin-right: 40px;
			cursor: pointer;
			border-bottom: 3px solid transparent;
			padding-bottom: 5px;
			margin-top: 8px;
			transition: all .2s;

            &--active-tab {
                border-color: #6eeab4;
            }

			&:hover {
				border-color: #6eeab4;
			}
		}
	}

	.header-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 25%;

		button {
			cursor: pointer;
		}
	}
`;
